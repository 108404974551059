import axios from 'axios'


export default {

    async getAll(params, callback) {
        return await axios.post("/app/dashboard/account/roles/getall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async getOne(params, callback) {
        return await axios.post("/app/dashboard/account/roles/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async add(params, callback) {
        return await axios.post("/app/dashboard/account/roles/add", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async edit(params, callback) {
        return await axios.post("/app/dashboard/account/roles/edit", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async delete(params, callback) {
        return await axios.post("/app/dashboard/account/roles/delete", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async savePermissions(params, callback) {
        return await axios.post("/app/dashboard/account/roles/savepermissions", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


}

