import axios from 'axios'


export default {

    async getAll(params, callback) {
        return await axios.post("/app/dashboard/product/products/getall", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async add(params, config, callback) {
        return await axios.post("/app/dashboard/product/products/add", params, config)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },


    async edit(params, config, callback) {
        return await axios.post("/app/dashboard/product/products/edit", params, config)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async changeQty(params, callback) {
        return await axios.post("/app/dashboard/product/products/changeqty", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async delete(params, callback) {
        return await axios.post("/app/dashboard/product/products/delete", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },



    async getOne(params, callback) {
        return await axios.post("/app/dashboard/product/products/getone", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },

    async barCode(params, callback) {
        return await axios.post("/app/dashboard/product/products/barcode", params)
            .then(res => {
                callback(res)
            })
            .catch(err => {
                console.log('response error ' + err)
            })
    },
}

