<template>
    <div>loading ...</div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    mounted() {
      this.$router.push('/dashboard')
    }
  };
  </script>